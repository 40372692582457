import { Routes, Route } from "react-router-dom";
import { lazy, Suspense, useEffect, useState } from "react";
import RuleRegulation from "./pages/RuleRegulation/RuleRegulation";
import PrivacyPolicy from "./pages/PrivacyPolicy/PrivacyPolicy";
import Spinner from "./components/Spinner";

const Home = lazy(() => import("./pages/Home/Home"));
const OurCars = lazy(() => import("./pages/Car/OurCars"));
const CarDetails = lazy(() => import("./pages/CarDetails/CarDetails"));
const Decoration = lazy(() => import("./pages/Decoration/Decoration"));
const DecorationDetails = lazy(() =>
  import("./pages/DecorationDetails/DecorationDetails")
);
const Contact = lazy(() => import("./pages/Contact/Contact"));

const App = () => {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const timeout = setTimeout(() => {
      setLoading(false);
    }, 1000);

    return () => clearTimeout(timeout);
  }, []);

  return (
    <div>
      {loading ? (
        <Spinner />
      ) : (
        <Suspense fallback={<Spinner />}>
          <Routes>
            <Route path="/" element={<Home />} />
            <Route  path="/nasze-samochody" element={<OurCars />} />
            <Route path="/samochód-szczegóły/:id" element={<CarDetails />} />
            <Route path="/dekoracje" element={<Decoration />} />
            <Route path="/regulamin" element={<RuleRegulation />} />
            <Route path="/polityka-prywatności" element={<PrivacyPolicy />} />
            <Route
              path="/dekoracje-szczegóły/:id"
              element={<DecorationDetails />}
            />
            <Route path="/kontakt" element={<Contact />} />
          </Routes>
        </Suspense>
      )}
    </div>
  );
};

export default App;
