import Logo1 from "../assets/spinnerlogo.svg";

const Spinner = () => (
  <div className="fixed top-0 left-0 right-0 bottom-0 z-50 flex justify-center items-center backdrop-blur-sm spinnerC">
    <div className="relative flex justify-center items-center">
      <div className="absolute animate-spin rounded-full h-32 w-32 border-t-4 border-b-4 border-[#C0A04C]"></div>
      <img src={Logo1} className="h-16 w-16" />
    </div>
  </div>
);

export default Spinner;
