import { useEffect, useState } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import "./Header.css";
// import logo from "../../assets/logo.png";
import DeskLogo from "../../assets/mainLogo.png";
import { FaSearch } from "react-icons/fa";
import { MdOutlineLocalPhone } from "react-icons/md";
import DarkMode from "../Toggle/DarkMode.jsx";
import HamburgerIcon from "../../assets/Menu.svg";
import CloseIcon from "../../assets/Frame.png";
import { url } from "../../config";
const Header = () => {
  const [menuOpen, setMenuOpen] = useState(false);
  const navigate = useNavigate();
  const [searchModalOpen, setSearchModalOpen] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  const [suggestions, setSuggestions] = useState([]);
  const [isScrolled, setIsScrolled] = useState(false);
  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };

  const toggleSearchModal = () => {
    setSearchModalOpen(!searchModalOpen);
  };
  const handleSearchInputChange = async (e) => {
    const query = e.target.value;
    setSearchQuery(query);

    if (query.length >= 3) {
      try {
        const response = await fetch(`${url}/api/search?query=${query}`);
        const data = await response.json();
        if (data.success) {
          setSuggestions(data.results);
        }
        console.log(data.results);
      } catch (error) {
        console.error("Error fetching search suggestions:", error);
      }
    } else {
      setSuggestions([]);
    }
  };

  const handleSuggestionClick = (item) => {
    if (item.type === "car") {
      navigate("/nasze-samochody");
    } else if (item.type === "decoration") {
      navigate("/dekoracje");
    }
  };
  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 40) {
        setIsScrolled(true);
      } else {
        setIsScrolled(false);
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);
  return (
    <header
      className={`py-4 lg:pl-12 lg:pr-12 md:px-4 px-3 fixed w-full top-0 z-40 transition-all duration-200 ${
        isScrolled ? "scrolled-navbar " : "default-navbar"
      }`}
    >
      <div className="container overflow-hidden  mx-auto flex justify-between items-center">
        <div
          className="text-2xl font-bold cursor-pointer"
          style={{ color: "var(--body_color)" }}
        >
          <NavLink to="/">
            <img
              src={DeskLogo}
              alt="logo"
              className="lg:w-[200px] lg:h-20 h-15 w-[120px]  block overflow-hidden"
            />
            {/* <h3
              className="logoMobile block lg:hidden"
              style={{ maxWidth: "240px" }}
            >
              Prime Wedding Cars
            </h3> */}
          </NavLink>
        </div>

        {/* Mobile Menu Button */}
        <div className="lg:hidden ">
          <button
            onClick={toggleMenu}
            className="text-gray-800 dark:text-white focus:outline-none cursor-pointer"
          >
            {menuOpen ? (
              <img src={CloseIcon} className="w-6 h-6" />
            ) : (
              <img src={HamburgerIcon} className="w-10 h-10 icon-gradient" />
            )}
          </button>
        </div>
        <nav className="hidden lg:flex items-center space-x-4">
          <NavLink
            to="/"
            className={({ isActive }) =>
              `menu-link  cursor-pointer ${
                isActive ? "active-link" : ""
              } hover-effect`
            }
            style={{
              color: isScrolled
                ? "var(--body_color)"
                : "var( --menu_default_color)",
            }}
          >
            STRONA GŁÓWNA
          </NavLink>
          <NavLink
            to="/nasze-samochody"
            className={({ isActive }) =>
              `menu-link  cursor-pointer ${
                isActive ? "active-link" : ""
              } hover-effect`
            }
            style={{
              color: isScrolled
                ? "var(--body_color)"
                : "var( --menu_default_color)",
            }}
          >
            SAMOCHODY
          </NavLink>
          <NavLink
            to="/dekoracje"
            className={({ isActive }) =>
              `menu-link cursor-pointer ${
                isActive ? "active-link" : ""
              } hover-effect`
            }
            style={{
              color: isScrolled
                ? "var(--body_color)"
                : "var( --menu_default_color)",
            }}
          >
            Dekoracje
          </NavLink>
          <NavLink
            to="/kontakt"
            className={({ isActive }) =>
              `menu-link  cursor-pointer ${
                isActive ? "active-link" : ""
              } hover-effect`
            }
            style={{
              color: isScrolled
                ? "var(--body_color)"
                : "var( --menu_default_color)",
            }}
          >
            KONTAKT
          </NavLink>
          <DarkMode />
          <button onClick={toggleSearchModal} className="cursor-pointer">
            <FaSearch
              className="text-gray-800 dark:text-white hover-effect"
              style={{
                color: isScrolled
                  ? "var(--body_color)"
                  : "var( --menu_default_color)",
                width: "20px",
                height: "20px",
              }}
            />
          </button>
          <div className="flex justify-center">
            <a href="tel: +48517661775">
              <button className="global-button flex items-center justify-center cursor-pointer text-white font-syne font-bold text-xs">
                <MdOutlineLocalPhone size={15} color="#fff" />
                <span style={{ fontFamily: "Inter, Arial, sans-serif" }}>
                  {"+48 517-661-775"}
                </span>
              </button>
            </a>
          </div>
        </nav>
        {menuOpen && (
          <div className="fixed inset-0 menuOpen h-full opacity-1 z-1000"></div>
        )}

        <nav
          className={`${
            menuOpen ? "fixed inset-0 flex flex-col z-20" : "hidden"
          } lg:hidden bg-[(var(--body_background)] p-5 transition duration-300 ease-in-out`}
        >
          <button
            onClick={toggleMenu}
            className="absolute top-4  left-4 icon-gradient flex items-center justify-center w-10 h-10 "
          >
            <img
              src={CloseIcon}
              className="w-6 h-6 cursor-pointer icon-gradient"
            />
          </button>
          <div className="flex flex-col  items-end justify-center top-3 space-y-5 p-5 z-1000">
            <NavLink
              to="/"
              className={({ isActive }) =>
                `menu-link  hom cursor-pointer ${
                  isActive ? "active-link " : ""
                } hover-effect`
              }
              style={{ color: "var(--body_color)" }}
            >
              STRONA GŁÓWNA
            </NavLink>
            <NavLink
              to="/nasze-samochody"
              className={({ isActive }) =>
                `menu-link  hom cursor-pointer ${
                  isActive ? "active-link" : ""
                } hover-effect`
              }
              style={{ color: "var(--body_color)" }}
            >
              SAMOCHODY
            </NavLink>
            <NavLink
              to="/dekoracje"
              className={({ isActive }) =>
                `menu-link  hom cursor-pointer ${
                  isActive ? "active-link" : ""
                } hover-effect`
              }
              style={{ color: "var(--body_color)" }}
            >
              Dekoracje
            </NavLink>
            <NavLink
              to="/kontakt"
              className={({ isActive }) =>
                `menu-link  hom con cursor-pointer ${
                  isActive ? "active-link" : ""
                } hover-effect`
              }
            >
              KONTAKT
            </NavLink>
            <DarkMode />
            <button onClick={toggleSearchModal} className="cursor-pointer">
              <FaSearch
                className="text-gray-800 dark:text-white hover-effect"
                style={{
                  color: "var(--body_color)",
                  width: "30px",
                  height: "30px",
                }}
              />
            </button>
            <div className="flex justify-center">
              <a href="tel: +48517661775">
                <button className="global-button flex items-center justify-center cursor-pointer text-white font-syne font-bold text-xs">
                  <MdOutlineLocalPhone size={20} color="#fff" />
                  <span style={{ fontFamily: "Inter, Arial, sans-serif" }}>
                    {"+48 517-661-775"}
                  </span>
                </button>
              </a>
            </div>
          </div>
        </nav>
      </div>

      {/* Search Modal */}
      {searchModalOpen && (
        <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-20">
          <div className="bg-white dark:bg-gray-800 rounded-lg p-4 w-96">
            <h2 className="text-lg text-gray-800   font-bold">Wyszukaj </h2>
            <input
              type="text"
              placeholder="Wyszukaj ..."
              value={searchQuery}
              onChange={handleSearchInputChange}
              className="border searchP rounded-lg p-2 w-full mt-2"
            />
            <div className="suggestions mt-2">
              {suggestions.map((item, index) => (
                <div
                  key={index}
                  className="suggestion-item  cursor-pointer p-2 rounded"
                  onClick={() => handleSuggestionClick(item)}
                >
                  <div>{item.location}</div>
                </div>
              ))}
            </div>
            <button
              onClick={toggleSearchModal}
              className="mt-4 text-gray-800  px-4 py-2 rounded cursor-pointer"
              style={{
                border: "2px solid transparent",
                borderImageSource:
                  "linear-gradient(180deg, #C4901B 0%, #916001 50%, #D6BD62 100%)",
                borderImageSlice: 1,
              }}
            >
              Zamknij
            </button>
          </div>
        </div>
      )}
    </header>
  );
};

export default Header;
