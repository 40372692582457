const PolicyPage = () => {
  return (
    <div className="px-4 py-6 mt-32 md:px-8 lg:px-16">
      <h1
        className="text-2xl md:text-3xl font-bold text-center mb-10"
        style={{ fontFamily: "Playfair Display, serif" }}
      >
        POLITYKA PRYWATNOŚCI
      </h1>
      <p
        className="mb-6"
        style={{
          fontFamily: "Lato, sans-serif",
        }}
      >
        Niniejsza Polityka Prywatności informuje użytkowników Serwisu „Prime
        Wedding Cars” (dostępnego pod adresem www.autanaslub.pl) o zasadach
        przetwarzania danych osobowych w kontekście korzystania z Serwisu.
        Korzystając z Serwisu, użytkownicy akceptują poniższe zasady.
      </p>
      <div
        className="space-y-4 text-justify text-sm md:text-base leading-relaxed"
        style={{
          fontFamily: "Lato, sans-serif",
        }}
      >
        <section>
          <h2 className="text-lg md:text-xl font-semibold mb-2">
            1. Administrator danych osobowych
          </h2>
          <p>
            1.1. Administratorem danych osobowych użytkowników Serwisu „Prime
            Wedding Cars” (dalej „Serwis”) jest firma Prime Wedding Cars,
            posiadająca siedzibę w [adres siedziby] (dalej „Usługodawca”).
          </p>
        </section>

        <section>
          <h2
            className="text-lg md:text-xl font-semibold mb-2"
            style={{
              fontFamily: "Lato, sans-serif",
            }}
          >
            2. Zakres zbieranych danych osobowych
          </h2>
          <p>
            2.1. Serwis nie zbiera ani nie przechowuje żadnych danych osobowych
            użytkowników.
          </p>
          <p>
            2.2. Dane osobowe użytkowników, takie jak imię, nazwisko, adres
            e-mail czy numer telefonu, są zbierane wyłącznie poprzez formularz
            kontaktowy, a następnie przesyłane bezpośrednio do Podmiotów
            Zewnętrznych (definicja opisana w Regulaminie Serwisu) lub
            Usługodawcy, z którymi użytkownik chce się skontaktować.
          </p>
          <p>
            2.3. Serwis nie przechowuje ani nie ma dostępu do danych
            przekazywanych za pośrednictwem formularza kontaktowego.
          </p>
        </section>

        <section>
          <h2
            className="text-lg md:text-xl font-semibold mb-2"
            style={{
              fontFamily: "Lato, sans-serif",
            }}
          >
            3. Cel przetwarzania danych osobowych
          </h2>
          <p>
            3.1. Dane osobowe są wykorzystywane wyłącznie w celu umożliwienia
            kontaktu z Podmiotami Zewnętrznymi w związku z zapytaniami
            użytkowników.
          </p>
          <p>
            3.2. Serwis nie przetwarza ani nie gromadzi danych w innych celach.
          </p>
        </section>
        <section>
          <h2
            className="text-lg md:text-xl font-semibold mb-2"
            style={{
              fontFamily: "Lato, sans-serif",
            }}
          >
            4. Odbiorcy danych osobowych
          </h2>
          <p>
            4.1. Dane osobowe użytkowników są przekazywane bezpośrednio do
            Podmiotów Zewnętrznych lub Usługodawcy, z którymi użytkownik
            nawiązuje kontakt za pośrednictwem formularza kontaktowego.
          </p>
          <p>
            4.2. Serwis nie udostępnia danych osobowych użytkowników osobom
            trzecim ani innym podmiotom.
          </p>
        </section>
        <section>
          <h2
            className="text-lg md:text-xl font-semibold mb-2"
            style={{
              fontFamily: "Lato, sans-serif",
            }}
          >
            5. Bezpieczeństwo danych osobowych
          </h2>
          <p>
            5.1. Serwis stosuje środki techniczne i organizacyjne w celu
            zapewnienia bezpieczeństwa danych osobowych użytkowników podczas ich
            przesyłania za pośrednictwem formularza kontaktowego.
          </p>
          <p>
            5.2. Formularz kontaktowy jest zabezpieczony protokołem SSL, co
            zapewnia ochronę danych przesyłanych między Użytkownikiem, a
            Serwisem.
          </p>
        </section>
        <section>
          <h2
            className="text-lg md:text-xl font-semibold mb-2"
            style={{
              fontFamily: "Lato, sans-serif",
            }}
          >
            6. Prawa użytkowników
          </h2>
          <p>6.1. Użytkownicy mają prawo do:</p>
          <ul
            className="list-disc list-inside space-y-1"
            style={{
              fontFamily: "Lato, sans-serif",
            }}
          >
            <li>Dostępu do swoich danych osobowych,</li>
            <li>Sprostowania swoich danych osobowych,</li>
            <li>
              Usunięcia swoich danych osobowych, o ile nie są one już niezbędne
              do realizacji celu, dla którego zostały zebraneSerwisu, stanowiący
              umowę pomiędzy Usługodawcą a Użytkownikiem.
            </li>
          </ul>
          <p>
            6.2. W celu realizacji powyższych praw Użytkownicy mogą skontaktować
            się bezpośrednio z Podmiotami Zewnętrznymi lub z Usługodawcą,
            wysyłając prośbę na adres e-mail kontakt@autanaslub.pl
          </p>
        </section>
        <section>
          <h2
            className="text-lg md:text-xl font-semibold mb-2"
            style={{
              fontFamily: "Lato, sans-serif",
            }}
          >
            7. Zmiany w Polityce Prywatności
          </h2>
          <p>
            7.1. Usługodawca zastrzega sobie prawo do wprowadzania zmian w
            Polityce Prywatności. Zmiany te będą publikowane na stronie
            internetowej Serwisu.
          </p>
          <p>
            7.2. Zmiany w Polityce Prywatności wchodzą w życie z chwilą ich
            publikacji na stronie Serwisu.
          </p>
        </section>
        <section>
          <h2
            className="text-lg md:text-xl font-semibold mb-2"
            style={{
              fontFamily: "Lato, sans-serif",
            }}
          >
            8. Postanowienia końcowe
          </h2>
          <p>
            8.1. Korzystanie z Serwisu wiąże się z akceptacją niniejszej
            Polityki Prywatności.
          </p>
          <p className="mb-12">
            8.2. W kwestiach nieuregulowanych niniejszą Polityką Prywatności
            zastosowanie mają przepisy obowiązującego prawa, w szczególności
            ustawy o ochronie danych osobowych oraz rozporządzenie RODO
          </p>
        </section>
        <div>
          <hr
            style={{
              borderColor: "var(--body_color)",
              backgroundColor: "var(--body_color)",
              borderWidth: "1px",
              borderStyle: "solid",
            }}
          />
        </div>
      </div>
      <p
        className="my-10 "
        style={{
          fontFamily: "Lato, sans-serif",
        }}
      >
        <strong>Kontakt:</strong> Jeśli masz pytania dotyczące Polityki
        Prywatności lub przetwarzania danych osobowych, skontaktuj się z nami
        wysyłając wiadomość na adres e-mail: {""}{" "}
        <a
          href="mailto:kontakt@autanaslub.pl"
          className="text-blue-500 underline"
        >
          kontakt@autanaslub.pl
        </a>
        {""} lub za pośrednictwem formularza kontaktowego umieszczonego na
        stronie internetowej{" "}
        <a href="https://www.autanaslub.pl" className="text-blue-500 underline">
          www.autanaslub.pl
        </a>{" "}
        w sekcji „Kontakt”
      </p>
    </div>
  );
};

export default PolicyPage;
