import Footer from "../../components/Footer/Footer";
import Header from "../../components/Header/Header";
import PolicyPage from "../../components/PolicyPage";

const PrivacyPolicy = () => {
  return (
    <div className="policy">
      <Header />
      <PolicyPage />
      <Footer />
    </div>
  );
};

export default PrivacyPolicy;
