import "./Footer.css";
import DeskLogo from "../../assets/mainLogo.png";
import TiktokIcon from "../../assets/tiktok.svg";
import FaceBookIcon from "../../assets/FacebookIcon.png";
import InstagramIcon from "../../assets/InstagramLogo.png";
import { Link } from "react-router-dom";
import { useEffect, useState } from "react";

const Footer = () => {
  const [isSmallScreen, setIsSmallScreen] = useState(false);

  useEffect(() => {
    const handleResize = () => setIsSmallScreen(window.innerWidth < 1024);
    window.addEventListener("resize", handleResize);
    handleResize();
    return () => window.removeEventListener("resize", handleResize);
  }, []);
  const getValidUrl = (url) => {
    return url.startsWith("http://") || url.startsWith("https://")
      ? url
      : `https://${url}`;
  };

  return (
    <>
      <footer
        className="lg:pt-16  pt-14 pb-8 px-4 lg:pl-12 lg:pr-12"
        style={{
          backgroundColor: "var(--body_background)",
          color: "var(--body_color)",
          borderTop: "1px solid #66676C",
          borderBottom: "1px solid #66676C",
          ...(isSmallScreen && {
            borderBottom: "1px solid #66676C",
            borderTop: "1px solid",
            borderImageSource:
              "linear-gradient(180deg, #C4901B 0%, #916001 50%, #D6BD62 100%)",
            borderImageSlice: 1,
          }),
        }}
      >
        <div className="container mx-auto flex flex-wrap justify-around gap-6 lg:gap-0 text-center lg:text-left">
          <div className="flex flex-col items-center lg:items-start w-full lg:w-[30%]">
            <img
              src={DeskLogo}
              alt="Footer Logo"
              className="lg:block hidden lg:w-[200px] lg:h-20 mb-2"
            />
            <img
              src={DeskLogo}
              alt="Footer Logo Mobile"
              className="block lg:hidden h-20 w-[200px]  mb-3"
            />
            <p
              className="mb-4 text-center lg:text-left"
              style={{
                fontFamily: "Lato, sans-serif",
                fontSize: "17px",
                fontWeight: 400,
                lineHeight: "20.4px",
              }}
            >
              Prime Wedding Cars-luksusowe auta na każdą okazję
            </p>
            <div className="flex space-x-4 justify-center lg:justify-start">
              <a
                href={getValidUrl(
                  "https://www.facebook.com/people/Prime-Wedding-Cars/61560037151194/"
                )}
                target="_blank"
                rel="noopener noreferrer"
              >
                <img
                  src={FaceBookIcon}
                  className="cursor-pointer"
                  alt="Facebook"
                />
              </a>
              <a
                href={getValidUrl(
                  "https://www.instagram.com/primeweddingcars/"
                )}
                target="_blank"
                rel="noopener noreferrer"
              >
                <img
                  src={InstagramIcon}
                  className="cursor-pointer"
                  alt="Instagram"
                />
              </a>
              <a
                href={getValidUrl(
                  "https://www.tiktok.com/@prime.wedding.cars"
                )}
                target="_blank"
                rel="noopener noreferrer"
              >
                <img
                  src={TiktokIcon}
                  className="cursor-pointer h-9 w-9"
                  alt="Tiktok"
                />
              </a>
            </div>
          </div>
          <div className="flex flex-col w-full lg:w-[25%] text-center lg:text-left">
            <h2
              className="headingGolden text-[32px] font-normal leading-[37.7px] mb-2"
              style={{ fontFamily: "Playfair Display, serif" }}
            >
              Kontakt
            </h2>
            <a
              href="tel:+48517661775"
              className="mb-2 text-lg"
              style={{ fontFamily: "Lato, sans-serif" }}
            >
              +48 517-661-775
            </a>

            <a
              href="mailto:kontakt@autanaslub.pl"
              className="mb-2 text-lg"
              style={{ fontFamily: "Lato, sans-serif" }}
            >
              kontakt@autanaslub.pl
            </a>
          </div>
          <div className="flex flex-col w-full lg:w-[25%] text-center lg:text-left">
            <h2
              className=" headingGolden text-[32px] font-normal leading-[37.7px] mb-2"
              style={{ fontFamily: "Playfair Display, serif" }}
            >
              Nawigacja
            </h2>
            <p className="mb-2 text-lg">
              <a
                href="/"
                className="hover:text-gray-400"
                style={{ fontFamily: "Lato, sans-serif" }}
              >
                Strona główna
              </a>
            </p>
            <p className="mb-2 text-lg">
              <a
                href="/nasze-samochody"
                className="hover:text-gray-400"
                style={{ fontFamily: "Lato, sans-serif" }}
              >
                Samochody
              </a>
            </p>
            <p className="mb-2 text-lg">
              <a
                href="/dekoracje"
                className="hover:text-gray-400"
                style={{ fontFamily: "Lato, sans-serif" }}
              >
                Dekoracje
              </a>
            </p>
            <p className="mb-2 text-lg">
              <a
                href="/kontakt"
                className="hover:text-gray-400"
                style={{ fontFamily: "Lato, sans-serif" }}
              >
                Kontakt
              </a>
            </p>
          </div>
          <div className="flex flex-col items-center w-full lg:w-[20%] text-center lg:text-left">
            <h2
              className=" headingGolden text-[32px] font-normal leading-[37.7px] mb-2"
              style={{ fontFamily: "Playfair Display, serif" }}
            >
              Lokalizacja
            </h2>
            <iframe
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2476.0695283972823!2d20.9563569!3d52.2207948!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x471ecb5c0f0c4487%3A0x8a68c61e88eb9e2c!2sGumi%C5%84ska%2C%2001-237%20Warszawa%2C%20Poland!5e1!3m2!1sen!2sin!4v1732804305880!5m2!1sen!2sin"
              width="450"
              height="200"
              style={{ border: 0 }}
              allowFullScreen=""
              loading="lazy"
              referrerPolicy="no-referrer-when-downgrade"
            ></iframe>
          </div>
        </div>
      </footer>
      <div className="container mx-auto flex flex-col sm:flex-row justify-between items-center mb-10 mt-8 text-sm text-center lg:text-left">
        <p
          className="w-full sm:w-auto text-[14px] lg:text-[17px] mb-4 sm:mb-0"
          style={{
            fontFamily: "Lato, sans-serif",
            fontWeight: 400,
            lineHeight: "20.4px",
          }}
        >
          Copyright © 2024 Prime Wedding Cars. All rights reserved.
        </p>
        <div className="flex space-x-4 lg:text-[17px] text-[12px] lg:block justify-center lg:justify-start">
          <a
            href="/polityka-prywatności "
            style={{
              fontFamily: "Lato, sans-serif",
              fontWeight: 400,
              lineHeight: "20.4px",
            }}
          >
            Polityka Prywatności
          </a>
          <a
            href="/regulamin "
            style={{
              fontFamily: "Lato, sans-serif",
              fontWeight: 400,
              lineHeight: "20.4px",
            }}
          >
            Regulamin
          </a>
        </div>
      </div>
      <div className="container mx-auto flex flex-col sm:flex-row justify-center items-center mb-10 mt-8 text-sm text-center lg:text-left">
        <p
          className="w-full sm:w-auto text-[14px] lg:text-[17px] mb-4 sm:mb-0"
          style={{
            fontFamily: "Lato, sans-serif",
            fontWeight: 400,
            lineHeight: "20.4px",
          }}
        >
          Hand crafted and made by{" "}
          <Link to="https://www.arcnine.in/" target="_blank">
            <span className="gradentTextF">Arcnine Solutions</span>
          </Link>
        </p>
      </div>
    </>
  );
};

export default Footer;
