const RulePage = () => {
  return (
    <div className="px-4 py-6 mt-32 md:px-8 lg:px-16">
      <h1
        className="text-2xl md:text-3xl font-bold text-center mb-6"
        style={{ fontFamily: "Playfair Display, serif" }}
      >
        REGULAMIN
      </h1>
      <div
        className="space-y-4 text-justify text-sm md:text-base leading-relaxed"
        style={{
          fontFamily: "Lato, sans-serif",
        }}
      >
        <section>
          <h2 className="text-lg md:text-xl font-semibold mb-2">1. WSTĘP</h2>
          <p>
            1.1. Niniejszy Regulamin określa warunki korzystania z serwisu
            internetowego pod nazwą „Prime Wedding Cars”, dostępnego pod
            adresem:{" "}
            <a
              href="https://www.autanaslub.pl"
              className="text-blue-500 underline"
            >
              www.autanaslub.pl
            </a>{" "}
            (zwany dalej „Serwisem”).
          </p>
          <p>
            1.2. Serwis nie wymaga logowania ani zakładania konta przez
            użytkowników. Użytkownik ma możliwość przeglądania ofert usług,
            wysyłania zapytań za pośrednictwem formularza kontaktowego lub
            kontaktowania się bezpośrednio pod wskazanym numerem telefonu.
            Korzystanie z Serwisu w powyższym zakresie stanowi akceptację
            niniejszego Regulaminu.
          </p>
          <p>
            1.3. Użytkownik zobowiązuje się do przestrzegania postanowień
            Regulaminu podczas korzystania z Serwisu.
          </p>
        </section>

        <section
          style={{
            fontFamily: "Lato, sans-serif",
          }}
        >
          <h2 className="text-lg md:text-xl font-semibold mb-2">
            2. DEFINICJE
          </h2>
          <p>2.1. Pojęcia pisane wielką literą mają następujące znaczenie:</p>
          <ul className="list-disc list-inside space-y-1">
            <li>
              <strong>Regulamin:</strong> niniejszy dokument regulujący zasady
              korzystania z Serwisu, stanowiący umowę pomiędzy Usługodawcą a
              Użytkownikiem.
            </li>
            <li>
              <strong>Serwis:</strong> strona internetowa pod nazwą „Prime
              Wedding Cars”, dostępna pod adresem:{" "}
              <a
                href="https://www.autanaslub.pl"
                className="text-blue-500 underline"
              >
                www.autanaslub.pl
              </a>
              , stanowiąca własność Usługodawcy.
            </li>
            <li>
              <strong>Usługodawca:</strong> Prime Wedding Cars; xxxxxxx, 01-247
              Warszawa, NIP: xxxxxxx, REGON: xxxx
            </li>
            <li>
              <strong>Użytkownik:</strong> osoba fizyczna lub firma korzystająca
              z Serwisu w celu wynajmu samochodów, pozyskania usług
              dekoracyjnych lub nawiązania współpracy marketingowej.
            </li>
          </ul>
        </section>

        <section  style={{
          fontFamily: "Lato, sans-serif",
        }}>
          <h2 className="text-lg md:text-xl font-semibold mb-2">
            3. WARUNKI ŚWIADCZENIA USŁUG
          </h2>
          <p>
            3.1. Serwis umożliwia prezentowanie ofert wynajmu samochodów oraz
            usług dekoracyjnych, a Usługodawca świadczy następujące usługi:
          </p>
          <ul className="list-disc list-inside space-y-1">
            <li>
              Świadczenie usług wynajmu pojazdów należących do Usługodawcy, tj.
              Mercedes CLA 35 AMG 4 MATIC 2021r. (grey magno) oraz Mercedes
              Klasa E 220D 4 MATIC 2024r. (biała perła), które posiadają ważne
              licencje uprawniające do przewozu osób samochodem osobowym,
              zgodnie z obowiązującymi przepisami prawa.
            </li>
            <li>
              Świadczenie usług marketingowych na rzecz Podmiotów Zewnętrznych,
              których oferty są prezentowane w Serwisie na podstawie umów
              marketingowych.
            </li>
          </ul>
          <p>
            3.2. W sekcji „Samochody” znajdują się zarówno pojazdy Usługodawcy,
            jak i pojazdy Podmiotów Zewnętrznych (właścicieli samochodów).
            Usługodawca:
          </p>
          <ul className="list-disc list-inside space-y-1">
            <li>
              Gwarantuje, że pojazdy należące do Usługodawcy posiadają wymagane
              licencje na przewóz osób samochodem osobowym oraz spełniają wymogi
              techniczne.
            </li>
            <li>
              Nie ponosi odpowiedzialności za to, czy pojazdy Podmiotów
              Zewnętrznych posiadają wymagane licencje, ani za stan techniczny
              tych pojazdów.
            </li>
          </ul>
          <p>
            3.3. W przypadku pojazdów Podmiotów Zewnętrznych, kontakt pomiędzy
            klientem a właścicielem pojazdu odbywa się za pośrednictwem
            formularza kontaktowego lub numeru telefonu. Strony samodzielnie
            uzgadniają warunki współpracy, w tym sposób zawarcia umowy.
            Usługodawca nie uczestniczy w tym procesie i świadczy wyłącznie
            usługę marketingową, promując oferty w Serwisie.
          </p>
          <p>
            3.4. W sekcji „Dekoracje” prezentowane są oferty Podmiotów
            Zewnętrznych oferujących usługi dekoracyjne oraz inne powiązane z
            tematyką ślubną, na podstawie zawartych umów marketingowych.
          </p>
          <p>
            3.5. W przypadku usługodawców z sekcji „Dekoracje”, kontakt pomiędzy
            klientem a podmiotem odbywa się za pośrednictwem formularza
            kontaktowego lub numeru telefonu. Warunki współpracy oraz sposób
            zawarcia umowy ustalane są samodzielnie przez strony. Usługodawca
            nie ingeruje w ten proces, świadcząc wyłącznie usługę marketingową.
          </p>
        </section>
        <section  style={{
          fontFamily: "Lato, sans-serif",
        }}>
          <h2 className="text-lg md:text-xl font-semibold mb-2">4. OPŁATY</h2>
          <p>
            4.1. Opłaty za wynajem pojazdów należących do Usługodawcy,
            wymienionych w punkcie 3.1, są ustalane indywidualnie z klientem po
            kontakcie telefonicznym lub za pośrednictwem formularza
            kontaktowego.
          </p>
          <p>
            4.2 W przypadku pojazdów należących do Podmiotów Zewnętrznych,
            wszelkie opłaty oraz sposób rozliczeń są ustalane bezpośrednio
            pomiędzy klientem, a właścicielem pojazdu. Usługodawca nie bierze
            udziału w procesie ustalania opłat ani rozliczeń pomiędzy stronami.
          </p>
          <p>
            4.3. W przypadku usługodawców z sekcji „Dekoracje”, opłaty oraz
            sposób rozliczeń są ustalane bezpośrednio pomiędzy klientem a danym
            Podmiotem Zewnętrznym. Usługodawca nie bierze udziału w ustalaniu
            cen ani rozliczaniu tych transakcji.
          </p>
        </section>
        <section>
          <h2 className="text-lg md:text-xl font-semibold mb-2">
            5. REKLAMACJE I ODPOWIEDZIALNOŚĆ
          </h2>
          <p>
            5.1. Usługodawca ponosi odpowiedzialność jedynie za realizację usług
            wynajmu pojazdów, o których mowa w punkcie 3.1 niniejszego
            Regulaminu, oraz za reklamacje dotyczące tych usług.
          </p>
          <p>
            5.2. Reklamacje dotyczące usługi wynajmu samochodów Podmiotów
            Zewnętrznych, prezentowanych w Serwisie w ramach umowy
            marketingowej, należy zgłaszać bezpośrednio do właściciela pojazdu,
            z którym klient zawarł umowę. Usługodawca nie ponosi
            odpowiedzialności za stan techniczny pojazdów Podmiotów
            Zewnętrznych, ani za realizację usługi wynajmu.
          </p>
          <p>
            5.3. Usługodawca nie ponosi odpowiedzialności za jakiekolwiek szkody
            wynikłe z umieszczania nieprawdziwych lub nieaktualnych informacji
            dotyczących pojazdów Podmiotów Zewnętrznych.
          </p>
          <p>
            5.4. Usługodawca nie ponosi odpowiedzialności za brak wymaganych
            licencji ani za stan techniczny pojazdów należących do Podmiotów
            Zewnętrznych.
          </p>
          <p>
            5.5. Usługodawca nie ponosi odpowiedzialności za jakość usług
            świadczonych przez Podmioty Zewnętrzne w sekcji „Dekoracje” Serwisu,
            w tym za jakość dekoracji, terminowość realizacji usług, oraz
            spełnienie warunków umowy przez te podmioty.
          </p>
          <p>
            5.6. Reklamacje dotyczące usług dekoracyjnych, w tym jakości
            wykonania dekoracji, należy zgłaszać bezpośrednio do podmiotu
            świadczącego daną usługę, z którym klient zawarł umowę.
          </p>
          <p>
            5.7. Usługodawca nie ponosi odpowiedzialności za nieprawdziwe,
            nieaktualne lub niepełne informacje dotyczące usług oferowanych
            przez Podmioty Zewnętrzne w sekcji „Dekoracje”.
          </p>
          <p>
            5.8. Usługodawca nie bierze odpowiedzialności za jakiekolwiek szkody
            wynikłe z niewłaściwego wykonania usług dekoracyjnych lub realizacji
            umowy z podmiotami oferującymi usługi w Serwisie.
          </p>
        </section>
        <section  style={{
          fontFamily: "Lato, sans-serif",
        }}>
          <h2 className="text-lg md:text-xl font-semibold mb-2">
            6. PRZETWARZANIE DANYCH OSOBOWYCH
          </h2>
          <p>
            6.1. Serwis nie zbiera ani nie przetwarza danych osobowych
            Użytkowników. Wszelkie dane podane w formularzu kontaktowym są
            przesyłane bezpośrednio do odpowiedniego właściciela pojazdu lub
            Usługodawcy.
          </p>
          <p>
            6.2. Usługodawca nie przechowuje ani nie przetwarza danych osobowych
            zawartych w formularzach kontaktowych.
          </p>
          <p>
            6.3. Szczegółowe zasady przetwarzania danych osobowych zostały
            określone w Polityce Prywatności dostępnej na stronie Serwisu.
          </p>
        </section>
        <section  style={{
          fontFamily: "Lato, sans-serif",
        }}>
          <h2 className="text-lg md:text-xl font-semibold mb-2">
            7. POSTANOWIENIA KOŃCOWE
          </h2>
          <p>
            7.1. Regulamin obowiązuje od dnia jego opublikowania na stronie
            internetowej www.autanaslub.pl
          </p>
          <p>
            7.2. Usługodawca zastrzega sobie prawo do wprowadzania zmian w
            Regulaminie. Informacja o zmianach będzie publikowana na stronie
            internetowej.
          </p>
          <p>
            7.3. W kwestiach nieuregulowanych niniejszym Regulaminem
            zastosowanie mają przepisy prawa powszechnie obowiązującego w
            Rzeczypospolitej Polskiej.
          </p>
        </section>
      </div>
    </div>
  );
};

export default RulePage;
